import './NeighborhoodCard.css';
import Modal from "../../Modal/Modal.tsx";
import { useState } from "react";
import React from 'react';

function NeighborhoodCard({ tourPlace }) {
    const [modalDisplayed, setModalDisplayed] = useState(false);

    const handleDisplayModal = (value) => {
        setModalDisplayed(value);
    };

    return (
        <div className="text-justify tour-card">
            <img src={tourPlace.image} alt="" className="width-100 hover-opac" />
            <div className="place-body bc-white color-black">
                <p className="margin-15-0">{tourPlace.desc}</p>
            </div>
            {modalDisplayed && <Modal handleDisplayModal={handleDisplayModal}></Modal>}
        </div>
    );
}

export default NeighborhoodCard;