import './Neighborhood.css';
import powerHoustonImg from '../../../assets/power_houston.jpg';
import sptImg from '../../../assets/celebrate_spt.jpg';
import redLibraryImg from '../../../assets/red_book_share.jpg';
import TourCard from "../NeighborhoodCard/NeighborhoodCard.tsx";
import React from 'react';

const tourPlaces = [
    {
        id: 1,
        name: 'Power Houston',
        image: powerHoustonImg,
        date: 'Fri 27 Nov 2016',
        desc: 'Powered Up!'
    },
    {
        id: 2,
        name: 'SPT',
        image: sptImg,
        date: 'Sat 28 Nov 2016',
        desc: 'Coming together to celebrate!'
    },
    {
        id: 3,
        name: 'Red Library',
        image: redLibraryImg,
        date: 'Sun 29 Nov 2016',
        desc: 'Finding a good book.'
    }
];

function Neighborhood() {
    return (
        <div id="register" className="padding-64-16 content-width margin-auto">
            <h2 className="letter-spacing-4 color-white">Getting Connected</h2>
            <p className="margin-15-0 h-opac color-white"><i>It's what we do!</i></p>
            <br />
            <div id="tour-places" className="flex-column-small">
                {tourPlaces.map((tourPlace) => <TourCard key={tourPlace.id} tourPlace={tourPlace} />)}
            </div>
        </div>
    );
}

export default Neighborhood;