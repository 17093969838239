import './Slider.css';
import powerHoustonImg from '../../assets/power_houston.jpg';
import sptImg from '../../assets/celebrate_spt.jpg';
import redLibraryImg from '../../assets/red_book_share.jpg';
import { useLayoutEffect, useState } from "react";
import React from 'react';

const slidePlaces = [
    {
        name: 'New York',
        desc: 'The atmosphere in New York is lorem ipsum.',
        image: powerHoustonImg
    },
    {
        name: 'Chicago',
        desc: 'Thank you, Chicago - A night we won\'t forget.',
        image: sptImg
    },
    {
        name: 'Los Angeles',
        desc: 'We had the best time playing at Venice Beach!',
        image: redLibraryImg
    }
];

function Slider() {
    const [slideIndex, setSlideIndex] = useState(0);

    useLayoutEffect(() => {
        const timerId = setTimeout(() => {
            setSlideIndex(prevIndex => (prevIndex + 1) % 3);
        }, 8000);

        // Cleanup the timer to avoid potential memory leaks
        return () => clearTimeout(timerId);
    }, [slideIndex]);

    return (
        <div id="home">
            <div id="slider">
                <div className="main-heading">Shepherd Park Terrace</div>
                <img className="slider" src={slidePlaces[slideIndex].image} alt="" />
                <div id="slide-heading" className="color-white text-center">
                    {/* <h3>{slidePlaces[slideIndex].name}</h3>
                <p className="margin-15-0 font-segoe"><b>{slidePlaces[slideIndex].desc}</b></p> */}
                </div>
            </div>
        </div>
    );
}

export default Slider;