import React from 'react';
import bandMember from '../../../assets/National_night_out.JPG';
import './Band.css';

function Band() {
    return (
        <div id="community" className="padding-64-16 content-width margin-auto">
            <h2 className="letter-spacing-4">THE COMMUNITY</h2>
            <p className="margin-15-0 h-opac"><i>We love music</i></p>
            <p className="margin-15-0 text-justify">Inspiration</p>
            <div className="display-flex padding-32-0 flex-column-small">
                <div className="width-third margin-bottom-15 width-100-small">
                    <p className="margin-15-0">Name</p>
                    <div>
                        <img src={bandMember} alt="" className="width-60 margin-auto border-radius-4" />
                    </div>
                </div>
                <div className="width-third margin-bottom-15 width-100-small">
                    <p className="margin-15-0">Name</p>
                    <div>
                        <img src={bandMember} alt="" className="width-60 margin-auto border-radius-4" />
                    </div>
                </div>
                <div className="width-third margin-bottom-15 width-100-small">
                    <p className="margin-15-0">Name</p>
                    <div>
                        <img src={bandMember} alt="" className="width-60 margin-auto border-radius-4" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Band;