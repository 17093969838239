import './globalStyles.css';
import './reset.css';
import Header from "./components/Header/Header.tsx";
import Slider from "./components/Slider/Slider.tsx";
import Content from "./components/Content/Content.tsx";
import Footer from "./components/Footer/Footer.tsx";
import React from 'react';

function App() {
  return (
    <div>
      <Header></Header>
      <Slider></Slider>
      <Content></Content>
      <Footer></Footer>
    </div>
  );
}

export default App;
