import './Content.css';
import Band from "./Band/Band.tsx";
import Neighborhood from "./Neighborhood/Neighborhood.tsx";
import Contact from "./Contact/Contact.tsx";
import neighborhoodMapImg from '../../assets/neighborhood_map.jpg';
import React from 'react';

function Content() {
    const containerStyle = {
        paddingTop: '50%',
        background: `url(${neighborhoodMapImg}) top center / cover no-repeat`
    };

    return (
        <div id="content" className="text-center">
            <Band></Band>
            <div className="bc-black">
                <Neighborhood></Neighborhood>
            </div>
            <Contact></Contact>
            <div style={containerStyle}></div>
        </div>
    );
}

export default Content;